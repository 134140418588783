<template>
  <div class="mind-box">
    <x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
      <p>分析中，请稍等</p>
      <bounce-loading></bounce-loading>
    </x-content>
    <div v-else>
  <div class="data_content" >
    <h1 class="gradient-text">新兴技术识别</h1>
    <el-input placeholder="请输入新兴技术识别关键词" v-model="query" style="width: 50%;margin: 0 auto;display: table;">
    <el-button type="success" slot="append" icon="el-icon-search" @click="getInfo">  分析</el-button>
    </el-input>
  <p v-html="thinkContent" class="thinkContent" ref="thinkContent"></p>
  <div class="loading-container" v-show="thinkLoading">
              <img
                src="/static/img/chatLoading.png"
                alt="Loading"
                class="loading-image"
              />
              <div class="loading-text">...</div>
            </div>
    <div 
        id="jsmind_container" 
        :style="{ width: '100%', height: '80vh' }"
      ></div>

</div>

</div>
  </div>
</template>

<script>
import 'jsmind/style/jsmind.css'
import jsMind from 'jsmind'

export default {
  name: 'EmergingTechnologies',
  data() {
    return {
      thinkContent:'',
      loading:false,
      thinkLoading:false,
      query:'',
      mindTree: '',
      jmInstance: null,
      colorList: [
        { bgc: '#409eff', bgc2: '#fff', bgc3: '#f9f6ff' },
        { bgc: '#27c4f7', bgc2: '#d8f3fa', bgc3: '#f2fcfd' },
        { bgc: '#38cb7b', bgc2: '#ddf3e7', bgc3: '#f2fbf6' },
        { bgc: '#fea24e', bgc2: '#fdebdb', bgc3: '#fff9f2' },
        { bgc: '#ef629f', bgc2: '#fddbea', bgc3: '#fff1f7' },
        { bgc: '#5091ff', bgc2: '#e4eeff', bgc3: '#f7faff' },
      ]
    }
  },
  mounted() {
    this.setDocumentTitle('新兴技术识别')

},
  beforeDestroy() {
    if (this.jmInstance) {
      document.getElementById('jsmind_container').removeEventListener('click', this.handleNodeClick)
    }
  },
  methods: {
     getInfo() {
        this.thinkContent = ''
        this.mindTree=''
        if (this.jmInstance) {
      document.getElementById('jsmind_container').removeEventListener('click', this.handleNodeClick)
    }
        this.loading=true
        // 在 Vue 组件中调用
        this.postPowerJSON('/power', 
        {
            inputs: {},
            query:this.query,
            response_mode: "streaming",
            conversation_id: "",
            user: "buxihuo"
        },
          {
            timeout: 300000,
            onStream: (data) => { // 流式数据回调
              const jsonString = JSON.stringify(data);
              if(!jsonString.includes("event: ping")){
                var partialData= JSON.parse(JSON.parse(jsonString));
                //console.log(partialData)
                if(partialData.event){
                  if(partialData.event=="agent_thought"){                
                    this.thinkLoading=true
                  }else{
                    this.thinkLoading=false
                  }
                  if(partialData.event=="agent_message"){                
                    const d=partialData.answer?partialData.answer:''
                    if(d){
                      this.loading=false
                    }
                    if(d.includes("[\n")){
                      this.mindTree =d
                      this.mindTree=this.convertToTreeStructure( this.mindTree);
    
                      this.initTree()
                      this.initMindMap()
                    }else{
                      this.thinkContent+=d
                      this.scrollToBottom()
                    
                    }
              }
            }
          }
        }
          })
          .then(finalData => {
           // console.log('完整数据:', finalData);
          })
          .catch(error => {
            console.error('请求失败:', error);
          });




    },

    initTree() {
      const processNode = (node, colorIndex, depth) => {
        //node.topic = this.limit(node.name)
        node.topic = node.name

        node.id = node.id
        //node["font-size"] = '14'
        if (depth === 0) {
          node["background-color"] = '#409eff'
          node["font-size"] = '14'
          node.direction = 'right'
        } else {
          
          const colorSet = this.colorList[colorIndex] || {}
          const colorKey = `bgc${depth > 3 ? 3 : depth}`
          node["background-color"] = colorSet[colorKey] || '#ecf5ff'
          node["font-size"] = '14'
          node["leading-line-color"] = this.colorList[colorIndex]?.bgc
          node["foreground-color"] = this.colorList[colorIndex]?.bgc
        }

        if (node.children) {
          node.children.forEach((child, index) => {
            processNode(child, colorIndex, depth + 1)
          })
        }
      }

      processNode(this.mindTree, 0, 0)
      this.mindTree.expanded = true
    },

    initMindMap() {
      const options = {
        container: 'jsmind_container',
        editable: false,
        theme: 'orange',//primary warning danger success info greensea nephrite belizehole wisteria asphalt orange pumpkin pomegranate clouds asbestos
        view: {
      line_color: '#999', // 设置连接线颜色为红色
      line_width: '2px', // 连接线宽度
      line_style: 'curved' // 连接线样式
    },
      }

      this.jmInstance = new jsMind(options)
      this.jmInstance.show({
        meta: {
          name: 'mindMap',
          author: 'jh',
          version: '0.1'
        },
        format: 'node_tree',
        data: this.mindTree
      })

      document.getElementById('jsmind_container').addEventListener('click', this.handleNodeClick)
    },

    handleNodeClick() {
      const selectedNode = this.jmInstance.get_selected_node()
      if (selectedNode) {
        console.log('Selected node ID:', selectedNode.id)
        // 这里可以触发自定义事件
        this.$emit('node-clicked', selectedNode)
      }
    },

    limit(str) {
      return str.length > 10 ? str.substring(0, 10) + '...' : str
    },
    convertToTreeStructure(originalData) {
      var data=originalData
      if(typeof originalData=='string'){
         data=JSON.parse(originalData)
      }
    
  // 判断是否是包含action_input的对象格式
  const dataArray = data.action_input || data;
  
  return {
    name: this.query + '新兴技术',
    id: 'root',
    children: dataArray.map((tech, index) => ({
      name: tech.技术名称,
      id: `root_${index + 1}`,
      children: Object.entries(tech.新兴性分析).map(([key, value], keyIndex) => ({
        name: `${key}: ${value}`,
        id: `root_${index + 1}_${keyIndex + 1}`,
        children: []
      }))
    }))
  };
},
  scrollToBottom() {
      this.$nextTick(() => {
        const thinkContent = this.$refs.thinkContent;
        thinkContent.scrollTop = thinkContent.scrollHeight;
      });
    },
  postPowerJSON(url, params, config = {}) {
  return new Promise((resolve, reject) => {
    const fullUrl = new URL(url, location.href).href;
    const controller = new AbortController();
    const timeoutId = setTimeout(() => {
      controller.abort();
      reject(new Error('请求超时'));
    }, config.timeout || 600000);

    fetch(fullUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer app-biOYxtDr0qw6HVBcw78OJWcC',
        ...config.headers,
      },
      body: JSON.stringify(params),
      signal: controller.signal,
    })
      .then(async (response) => {
        clearTimeout(timeoutId);
        if (!response.ok) throw new Error(`HTTP 错误! 状态码: ${response.status}`);

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = ''; // 缓冲区保存未处理的数据
        let accumulatedData = ''; // 累积所有事件的完整数据

        try {
          while (true) {
            const { done, value } = await reader.read();
            const chunk = decoder.decode(value, { stream: !done });

            if (chunk) {
              // 处理每行，移除行首的 data: 前缀
              const processedChunk = chunk.split('\n')
                .map(line => line.replace(/^data:\s*/, ''))
                .join('\n');
              buffer += processedChunk;

              // 分割完整事件（基于双换行符）
              const eventParts = buffer.split('\n\n');
              const completeEvents = eventParts.slice(0, -1);
              buffer = eventParts.pop() || ''; // 剩余部分放回缓冲区

              // 处理每个完整事件
              completeEvents.forEach(eventData => {
                // 合并事件内的换行（根据需求调整）
                const data = eventData.replace(/\n/g, '');
                accumulatedData += data;
                if (config.onStream) config.onStream(data);
              });
            }

            if (done) {
              // 处理缓冲区剩余数据
              if (buffer) {
                const data = buffer.replace(/\n/g, '');
                accumulatedData += data;
                if (config.onStream) config.onStream(data);
              }
              resolve(accumulatedData);
              break;
            }
          }
        } catch (streamError) {
          reject(streamError);
        }
      })
      .catch((error) => {
        clearTimeout(timeoutId);
        reject(error);
      });
  });
}
  }
}
</script>

<style >
.mind-box {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 4px;
  overflow: auto;
}
.mind-box::-webkit-scrollbar{
		width: 6px;
	}
  .mind-box::-webkit-scrollbar-thumb{
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.6);
		opacity: 0.2;
		background: fade(#8398de, 90%);
	}
 jmnode{
  max-width: 900px !important;
  box-shadow: none !important;
}
jmnode.selected{
  background-color: #67c23a !important;
}
.data_content{
    padding: 20px !important;
}

  .thinkContent,.thinkContent details{
  line-height: 26px !important;
  margin-bottom: 10px;

}
.loading-container {
  display: flex;
  align-items: center; /* 垂直居中 */
  font-family: Arial, sans-serif;
}
.loading-text {
  margin-left: 3px; /* 文字与图片的间距 */
  font-size: 14px;
  color: #666;
}
.loading-image {
  width: 30px; /* 图片大小 */
  height: 30px; /* 图片大小 */
  border-radius: 50%; /* 使图片变成圆形（可选） */
  animation: spin 1s linear infinite; /* 应用旋转动画 */
}
/* 定义旋转动画 */
@keyframes spin {
  0% {
    transform: rotate(0deg); /* 起始角度 */
  }
  100% {
    transform: rotate(360deg); /* 结束角度 */
  }
}
.gradient-text {
  /* 基础样式 */
  font-size: 48px;
  letter-spacing: 5px;
  text-align: center;
  margin-bottom: 30px;
  background-image: linear-gradient(246deg,#856efa,#1366ec 64%,#5c99ff 99%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

</style>